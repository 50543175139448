<script setup lang="ts">
import type { IRate } from "~/interfaces";
const locale = await getLocale("forms", "rate_power_credit");
import { removeZeroDecimals } from "~/utils/prices";

import BaseForm from "~/components/ui/BaseForm.vue";
import Rata from "~/components/inputs/Rata.vue";

defineComponent({
  name: "RatePowerCreditUpgrade",
});
const emit = defineEmits(["init", "submit", "upgradeRateValue"]);

const model = ref<IRate>(getPromoModel()!);
const upgradeModel = ref<IRate>(getPromoUpgradeModel()!);
const selected = ref<IRate>(upgradeModel.value);

const impRata = computed(() => {
  return removeZeroDecimals(model.value.imp_rata)
});

const text = computed(() => {
  return `Rata <strong>${impRata.value} €</strong> per <strong>${model.value.rate}</strong> mesi <strong>TAEG ${model.value.taeg}%</strong>`
});

const textAlt = computed(() => {
  return `<span class="md:hidden"><strong>${model.value.imp_credito}</strong> €</span> <span class="md:capitalize">rata</span> <strong>${impRata.value} €</strong> per <strong>${model.value.rate}</strong> mesi TAEG <strong>${model.value.taeg}%</strong>`
});

const textUpgrade = computed(() => {
  return `Rata <strong>${upgradeModel.value.imp_rata} €</strong> TAEG <strong>${upgradeModel.value.taeg}%</strong>`
});

const textUpgradeAlt = computed(() => {
  return `<span class="md:hidden"><strong>${upgradeModel.value.imp_credito}</strong> €</span> <span class="md:capitalize">rata</span> ${upgradeModel.value.imp_rata} € TAEG ${upgradeModel.value.taeg}%`
});

const isActive = (model: IRate) => {
  return selected.value === model
};
const toggleSelected = (rate: IRate) => {
  if (window.innerWidth < 1024) selected.value = rate;
};

const submit = (model: IRate) => {
  emit("submit", model);
};
onMounted(() => emit("init"));
</script>

<template>
  <BaseForm>
    <template #upper>
      <div class="offers my-4 sm:my-8">
        <div class="px-2 sm:px-16 mx-auto lg:container pt-6">
          <ul class="mb-4 lg:mb-6" data-testid="rataOur600">
            <Rata
              :model="model"
              :active="isActive(model)"
              type="our"
              :text="text"
              :text-alt="textAlt"
              @selected="toggleSelected"
              @submit="submit"
            />
          </ul>
          <div>
            <h2
              class="text-orange text-center font-semibold text-xl sm:text-3xl mb-4 mt-10"
            >
              {{ locale.title_power_upgrade }}
            </h2>
            <h3 class="text-base text-center mb-6">
              {{ locale.subtitle_power_upgrade }}
            </h3>
          </div>
          <div class="mb-12" data-testid="rataOur2000">
            <Rata
              class="mb-2"
              :model="upgradeModel"
              :active="isActive(upgradeModel)"
              :text="textUpgrade"
              :text-alt="textUpgradeAlt"
              type="our"
              @selected="toggleSelected"
              @submit="submit"
            />
          </div>
        </div>
      </div>
      <div class="spacer h-6"></div>
    </template>
  </BaseForm>
</template>

<style scoped></style>
